// mission vision values component

import React from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/mission-vision-values.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';


const MissionVisionValues = () => {
    return (
        <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <div className='mvv-card text-center rounded p-3'>
                        <h4 className="fw-bold mt-2 mb-3">Mission</h4>
                        <div className='text-center mb-3'>
                            <img src="https://core.ps/assets/global/mission.png" alt="mission" className='img-fluid mvv-img' />
                        </div>
                        <p className="lead" align='justify'>
                            Catalyze economic growth through expertise and collaboration. We provide strategic guidance, facilitate informed decision-making, and offer capacity-building solutions in economic and trade policy, negotiation, and private sector development. Through dedication, strategic foresight, and collaborative spirit, we aim to be the catalyst for positive change in the economic landscape, fostering prosperity and stability for all
                        </p>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <div className='mvv-card text-center rounded p-3'>
                        <h4 className="fw-bold mt-2 mb-3">Vision</h4>
                        <div className='text-center mb-3'>
                            <img src="https://core.ps/assets/global/vision.png" alt="vision" className='img-fluid mvv-img' />
                        </div>
                        <p className='lead' align='justify'>
                            To be the driving force behind economic prosperity, recognized for our unwavering commitment to excellence, innovation, and sustainable growth. We aspire to empower the private sector, foster economic resilience, and contribute to a brighter future for all
                        </p>
                    </div>
                </div>
                <div className='px-4 col-lg-12 col-md-12 col-12'>
                    <div className="row mb-3 justify-content-center align-items-center values-card text-center rounded">
                        <div className="col-sm-12 col-md-12 col-lg-3 p-2 values-div">
                            <div className=''>
                                <h4 className="fw-bold mt-1">Values</h4>
                                <div className='text-center mb-1'>
                                    <img src="https://core.ps/assets/global/values.png" alt="values" className='img-fluid mvv-img' />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-9 mb-2">
                            <ValuesSlider />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ValuesSlider = () => {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                pagination={{
                    clickable: true
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='items-align-center p-5'>
                        <h4> Empowering Businesses </h4>
                        <p className="lead">
                            Equipping enterprises with the tools and knowledge needed to excel in a competitive global marketplace. We support their growth, enhance their competitiveness, and expand their market reach.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='items-align-center mt-2 p-5'>
                        <h4> Influencing Trade Policies </h4>
                        <p className="lead">
                            Advising governments and stakeholders on trade policies that align with international standards, facilitate commerce, and create a conducive environment for business growth.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='items-align-center mt-2 p-5'>
                        <h4> Building Capacities </h4>
                        <p className="lead">
                            Investing in human capital and institution building to strengthen the foundations of economic development. We foster collaboration between public and private sectors, academia, and civil society.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='items-align-center mt-2 p-5'>
                        <h4> Driving Innovation </h4>
                        <p className="lead">
                            Leveraging our expertise to drive innovation and efficiency in economic practices, leading to sustainable development and increased opportunities for high-growth startup businesses.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='items-align-center mt-2 p-5'>
                        <h4> Ensuring Inclusivity </h4>
                        <p className="lead">
                            Promoting inclusivity by working with diverse stakeholders and supporting small and medium-sized enterprises (SMEs) to ensure broad-based economic growth.
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default MissionVisionValues;