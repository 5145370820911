import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/AreasOfFocusCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faChartBar, faTree, faLightbulb, faUsers } from '@fortawesome/free-solid-svg-icons';

const FocusAreas = () => {
  return (
    <div className="container bg-light p-3 rounded">
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 ">
          <div className="row align-items-center custom-row mt-4 slideanim">
            <div className="col-4 text-center">
              <FontAwesomeIcon icon={faChartPie} size="5x" className=" custom-icon" />
            </div>
            <h6 className="col-8">Trade & Economy</h6>
          </div>
          <div className="row align-items-center custom-row mt-5">
            <div className="col-4 text-center"> 
              <FontAwesomeIcon icon={faChartBar} size="5x" className="custom-icon" />
            </div>
            <h6 className='col-8'>Policy & Planning</h6>
          </div>
          <div className="row align-items-center custom-row mt-5">
            <div className="col-4 text-center">
              <FontAwesomeIcon icon={faTree} size="5x" className="custom-icon" />
            </div>
            <h6 className='col-8 custom-h6'>Agriculture, food security, environment</h6>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row align-items-center custom-row mt-5">
            <div className="col-4 text-center">
              <FontAwesomeIcon icon={faLightbulb} size="5x" className="custom-icon" />
            </div>
            <h6 className="col-8 custom-h6">Youth socio-economic empowerment, Innovation</h6>
          </div>
          <div className="row align-items-center custom-row mt-5">
            <div className="col-4 text-center"> 
              <FontAwesomeIcon icon={faUsers} size="5x" className="custom-icon" />
            </div>
            <h6 className="col-8 custom-h6">Social Development and Protection</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FocusAreas;
