// about us page

// import react
import React from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

// import components
import AboutCard from '../components/AboutCard';
import Team from '../components/Team';
import AboutOverview from '../components/AboutOverview';
import MissionVisionValues from '../components/mission-vision-values';

// import fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';





const About = () => {
    return (
        <div>
            <header >
                {window.innerWidth > 768 ? (
                    <AboutCard />
                ) : (
                    <div className='text-center mb-2'>
                        <h1 className='text-uppercase title'>
                            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                            <span className='px-3'>
                                About Us
                            </span>
                            <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                        </h1>
                    </div>
                )}
                <br />
            </header>
            <body className="container" >
                <AboutOverview />
                <br />
                <div className="">
                    <div className='text-center mb-5'>
                        <h1 className='text-uppercase title'>
                            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                            <span className='px-3'>
                                Misson, Vision, & Values
                            </span>
                            <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                        </h1>
                    </div>
                    <MissionVisionValues />
                </div>
                <hr />
                <div className="row">
                    <div className='text-center mb-5'>
                        <h1 className='text-uppercase title'>
                            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                            <span className='px-3'>
                                Our Team
                            </span>
                            <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                        </h1>
                    </div>

                    <Team />
                </div>

            </body>
        </div>
    );
}

export default About;
