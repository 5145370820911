// google maps component

import React from 'react';
import { useMemo } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';


//import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/GoogleMap.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';


const GoogleMapComponent = () => {
    // console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyB-93Dzun6-lLWnV1ieOVBd4zXQPOH04Ik" });
    if (!isLoaded) {
        return <div className='p-5 mb-3'>Loading...</div>;
    }
    return (
        <div className=' mb-3'>
            <div className='row mb-5'>
                <div className="container google-map-contact-wrapper col-lg-3 col-md-12 col-12 p-4">
                    <div className="">
                        <h3>Core Associates</h3>
                        <hr />
                        <div >
                            <ul className='list-unstyled'>
                                <li className="row align-items-center">
                                    <span className='col-2 text-center'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} aria-label="marker-icon" size='2x' color='grey' />
                                    </span>
                                    <span className="col-10">Watanieh Tower. Suite 201. <br></br>El-Bireh / Ramallah.</span>
                                </li>
                                <hr  />
                                <li className="row align-items-center">
                                    <span className="col-2 gx-1 text-center">
                                        <FontAwesomeIcon icon={faPhone} aria-label="phone-icon" size='2x' color='grey' />
                                    </span>
                                    <span className='col-10'>
                                        <a href="tel:+970-2-2412102">+970-2-2412102</a>
                                    </span>
                                </li>
                                <hr  />
                                <li className="row align-items-center">
                                    <span className="col-2 gx-1 text-center">
                                        <FontAwesomeIcon icon={faEnvelope} aria-label="mail icon" size='2x' color='grey' />
                                    </span>
                                    <span className='col-10'>
                                        <a href="mailto:info@core.ps">info@core.ps</a>
                                    </span>
                                </li>
                                <hr />
                                <li className="row align-items-center">
                                    <span className="col-2 gx-1 text-center">
                                        <FontAwesomeIcon icon={faLink} aria-label="website-link-icon" size='2x' color='grey' />
                                    </span>
                                    <span className='col-10'>
                                        <a target="_blank" href="https://www.core.ps" rel="noreferrer">www.core.ps</a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <Map />
                </div>
            </div>
        </div>
            );
};

const Map = () => {

    const center = useMemo(() => ({
                address: 'Watanieh Tower. Suite 201. El-Bireh, Ramallah.',
            lat: 31.901829405989975,
            lng: 35.21112817650279
    })  , []);

    const mapOptions = useMemo(() => ({
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            fullscreenControl: true,

            
    }), []);

    const customMarker = useMemo(() => ({
        url: 'https://core.ps/assets/global/core-marker.png',
    }), []);


            return (
            <GoogleMap mapContainerClassName='map-container' center={center} zoom={17} options={mapOptions} >
                <MarkerF position={center} icon={customMarker}/>
            </GoogleMap>

            );
};


            export default GoogleMapComponent;