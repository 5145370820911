/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/navbar.css';

// import font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars} from '@fortawesome/free-solid-svg-icons';

const CustomNavbar = () => {
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);

  const handleAboutMouseEnter = () => {
    setIsAboutDropdownOpen(true);
  };

  const handleAboutMouseLeave = () => {
    setIsAboutDropdownOpen(false);
  };

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const navbar = document.getElementById('custom-navbar');
    const handleScroll = () => {
        setSticky(window.scrollY > navbar.offsetTop);
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }, []);


  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen(!isAboutDropdownOpen);
  }





  return (
      <nav className={`${sticky ? 'sticky navbar navbar-expand-lg navbar-light bg-white' : 'navbar navbar-expand-lg navbar-light bg-white'}`} id="custom-navbar">
        <a className="navbar-brand" href="/">
          <img
            src="https://core.ps/assets/global/core-logo.png"
            className="d-inline-block align-top"
            width="160"
            height="60"
            alt="core logo"
          />
        </a>
        <button
          className="navbar-toggler custom-toggler-icon"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#basic-navbar-nav"
          aria-controls="basic-navbar-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
        <div className="collapse navbar-collapse justify-content-end m-3 p-1" id="basic-navbar-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/" className="nav-link custom-nav-link">Home</a>
            </li>
            <li
              className={`nav-item dropdown ${isAboutDropdownOpen ? 'show' : ''}`}
              onMouseEnter={handleAboutMouseEnter}
              onMouseLeave={handleAboutMouseLeave}
            >
            { window.innerWidth < 992 ? (
              <a
                href="#"
                className="nav-link custom-nav-link dropdown-toggle"
                id="aboutDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded={isAboutDropdownOpen}
                onClick={toggleAboutDropdown}
              >
                About
              </a>
            ) : (
              <a
                href="/about"
                className="nav-link custom-nav-link dropdown-toggle"
                id="aboutDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded={isAboutDropdownOpen}
              >
                About
              </a>
            )}
              <ul
                className={`dropdown-menu ${isAboutDropdownOpen ? 'show' : ''}`}
                aria-labelledby="aboutDropdown"
              >
                <li className="dropdown-item custom-nav-link"><a className="dropdown-item" href="/about">Company Overview</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li className="dropdown-item custom-nav-link"><a className="dropdown-item" href="/partners-and-clients">Partners & Clients</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="/services" className="nav-link custom-nav-link">Services</a>
            </li>
            <li className="nav-item">
              <a href="/projects" className="nav-link custom-nav-link">Projects</a>
            </li>
            <li className="nav-item">
              <a href="/contact" className="nav-link custom-nav-link">Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
  );
};

export default CustomNavbar;
