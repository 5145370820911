// carousel component
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/carousel.css';

const CustomCarousel = () => {
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchImageFilenames = async () => {
      try {
        const res = await fetch('https://core.ps/assets/core-pics/');
        const data = await res.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const imageElements = doc.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]');
        const imageFilenames = Array.from(imageElements).map(element => element.textContent.trim());
        // console.log(imageFilenames);
        setImages(imageFilenames);
      } catch (err) {
        console.error(err);
      }
    };
    fetchImageFilenames();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change slide every 10 seconds

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  const prevSlide = () => {
    setActiveIndex((activeIndex) => (activeIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setActiveIndex((activeIndex) => (activeIndex + 1) % images.length);
  };

  return (
    <div className="carousel slide custom-carousel" data-ride="carousel" data-aos="zoom-out">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
            <img className=" d-block w-100" src={`https://core.ps/assets/core-pics/${image}`} alt={`Slide ${index}`} />
          </div>
        ))}
        <button
          className="carousel-control-prev"
          href="#"
          role="button"
          data-slide="prev"
          onClick={prevSlide}
        >
          <span className="bg-dark p-2 carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button
          className="carousel-control-next"
          href="#"
          role="button"
          data-slide="next"
          onClick={nextSlide}
        >
          <span className="bg-dark p-2 carousel-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>
      <a href="/contact" className="">
        <div className="carousel-footer btn btn-primary custom-btn col-lg-3 justify-content-end rounded fw-semibold">
          Work With Us
        </div>
      </a>
    </div>
  );
};

export default CustomCarousel;
