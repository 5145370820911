/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/ClientCarousel.css';

const ClientCarousel = () => {
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsPerPage = 3;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Fetch the directory listing HTML page
        const res = await fetch('https://core.ps/assets/clients/');
        const text = await res.text();

        // Parse the HTML content to extract image URLs
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const links = doc.querySelectorAll('a[href$=".jpeg"], a[href$=".png"], a[href$=".jpg"], a[href$=".gif"]');

        // Extract and set the image URLs
        const imageUrls = Array.from(links).map((link) => link.getAttribute('href'));
        // console.log(imageUrls);
        setImages(imageUrls);
      } catch (err) {
        console.error(err);
      }
    };
    fetchImages();
  }, []);

  const nextSlide = () => {
    setActiveIndex((activeIndex) => (activeIndex + 1) % chunkedImages.length);
  };

  const prevSlide = () => {
    setActiveIndex((activeIndex) =>
      (activeIndex - 1 + chunkedImages.length) % chunkedImages.length
    );
  };

  // Function to chunk the images array into groups of 3
  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const chunkedImages = chunkArray(images, itemsPerPage);

  return (
    <div id="client-caoursel" className="image-carousel client-carousel" data-ride="carousel">
      <div className="carousel-inner p-4">
        <button
          className="carousel-control-prev"
          href="#client-caoursel"
          role="button"
          data-slide="prev"
          onClick={prevSlide}
        >
          <span id="client-carousel-prev" className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
        </button>
        <button
          className="carousel-control-next"
          href="#client-caoursel"
          role="button"
          data-slide="next"
          onClick={nextSlide}
        >
          <span id="client-carousel-next" className="carousel-control-next-icon bg-dark client-carousel" aria-hidden="true"></span>
        </button>
        {chunkedImages.map((chunk, chunkIndex) => (
          <div key={chunkIndex} className={`carousel-item ${chunkIndex === activeIndex ? 'active' : ''}`}>
            <div className="row p-5 bg-light">
              {chunk.map((image, index) => (
                <div key={index} className="col-12 col-md-4 d-flex align-items-center mt-3 ">
                  <div className='col-12 bg-white p-3 custom-div'>
                    <img
                      className="rounded mx-auto d-block"
                      src={`https://core.ps/assets/clients/${image}`}
                      alt={`Slide ${index}`}
                      style={{
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientCarousel;
