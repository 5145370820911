import React from 'react';
import { Collapse } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



function Tabs({ activeTab, setActiveTab, showTabs, setShowTabs }) {

  const toggleTabs = () => {
    setShowTabs(!showTabs);
  };

  return (
    <div className="tabs">
      {window.innerWidth > 768 ? (
        <div className=''>
          <button
            className={`tab ${activeTab === 'All' ? 'active' : ''} custom-btn rounded-start`}
            onClick={() => setActiveTab('All')}
          >
            All
          </button>
          <button
            className={`tab ${activeTab === 'Complete' ? 'active' : ''} custom-btn rounded-0 `}
            onClick={() => setActiveTab('Complete')}
          >
            Completed
          </button>
          <button
            className={`tab ${activeTab === 'Ongoing' ? 'active' : ''} custom-btn rounded-end`}
            onClick={() => setActiveTab('Ongoing')}
          >
            Ongoing
          </button>
        </div>
      ) : (
        <div className="tabs">
          <button
            type="button"
            className="btn custom-btn mb-1"
            onClick={toggleTabs} // Use toggleFilters to toggle visibility
            aria-expanded={showTabs}
          >
            {showTabs ? 'Hide Status Filter' : 'Show Status Filter'}
          </button>

          <Collapse in={showTabs}>
            <div>
              <div className='text-end'>
                <button className='btn fw-light mb-1' onClick={() => { setActiveTab('All'); }}>
                  Clear Selection
                </button>
              </div>
              <div className='row px-3'>
                <button
                  className={`tab col-3 ${activeTab === 'All' ? 'active' : ''} custom-btn rounded-top`}
                  onClick={() => setActiveTab('All')}
                >
                  All
                </button>
                <button
                  className={`tab col-3 col-md-3 ${activeTab === 'Complete' ? 'active' : ''} custom-btn `}
                  onClick={() => setActiveTab('Complete')}
                >
                  Completed
                </button>
                <button
                  className={`tab col-3 col-md-3 ${activeTab === 'Ongoing' ? 'active' : ''} custom-btn rounded-bottom`}
                  onClick={() => setActiveTab('Ongoing')}
                >
                  Ongoing
                </button>
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
};



export default Tabs;
