// main app component

// import react
import React, { useEffect } from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';


// import js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';

//import aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// import components
import CustomNavbar from './components/navbar';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';

// import Routes
import Router from './Routes.js';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-sine',
      delay: 100,
      anchorPlacement: 'bottom-bottom'
      
    });
  }, []);

  return (
    <div className="" style={{overflow:'hidden'}}>
      <header className=" container">
        {/* <div style={{height: '82px'}}></div> */}
        <CustomNavbar />
        
      </header>

      <main className="">
        <Router />
      </main>

      <footer className='mt-auto'>
        <Footer />
        <ScrollToTopButton />
      </footer>
    </div>
  );
}

export default App;
