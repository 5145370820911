// home page component
// import react
import React  from 'react';

// import css
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

// import components
import CustomCarousel from '../components/carousel';
import ServicesCard from '../components/ServicesCard';
import AreasOfFocusCard from '../components/AreasOfFocusCard';
import ClientCarousel from '../components/ClientCarousel';


const Home = () => {
    return (
        <div>
            <CustomCarousel />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-8 mt-3" data-aos="fade-bottom-right" data-aos-duration="1000">
                        <h1 className=" display-4  fw-bold">Welcome to Core Associates</h1>
                        <div className="justify-content-md-start mb-5">
                            <h5 className=' mt-3' align='justify'> CORE ASSOCIATES was established in October, 2010 as a Limited Liability Private 
                            Shareholding Company in accordance with Palestinian Company Registration requirements. We operate in the following
                            Areas of Focus:</h5>
                        </div>
                        <AreasOfFocusCard />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mt-4" data-aos="fade-left" data-aos-duration="1000">
                        <ServicesCard />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-out-up" >
                        <h1 className=" display-4 fw-bold">Our Clients & Partners</h1>
                        <ClientCarousel />
                    </div>
                </div>
            </div>
        </div>
    );
    }

export default Home;
