// team bio component

import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/Team.css';

// import team.json file
import team from '../team.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


const Team = () => {
  // Sample team data
  const teamMembers = team
  console.log(teamMembers.length)

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [visibleMembers, setVisibleMembers] = useState(2); // Initial number of visible team members
  const [isExpanded, setIsExpanded] = useState(false); // New state for expansion
  console.log(visibleMembers)

  const showMemberModal = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  const loadMoreMembers = () => {
    setVisibleMembers(visibleMembers + 2);
    setIsExpanded(true);
  };

  const showLessMembers = () => {
    setVisibleMembers(visibleMembers - 2);
    if (visibleMembers <= 4) {
      setIsExpanded(false);
    }
  };

  return (
    <div className="container team-div">
      <div className="row">
        {teamMembers.slice(0, visibleMembers).map((member, index) => (
          <div key={index} className="col-lg-6 col-md-6 col-sm-6 mb-5" data-aos="fade-left" data-aos-delay="300">
            <Card className="team-card border-0" onClick={() => showMemberModal(member)} style={{ cursor: 'pointer' }}>
              {member.image && (
                <Card.Img
                  variant="top"
                  className="rounded-circle card-img"
                  src={member.image}
                  alt={member.name}
                  style={{ objectFit: 'cover' }}

                />
              )}
              <Card.Body className='text-center d-flex flex-column'>
                <Card.Title>{member.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{member.title}</Card.Subtitle>
                <Card.Text className="click-prompt">Click for more details</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      {visibleMembers < teamMembers.length && (
        <div>
          {isExpanded ? (
            <div className='text-center justify-space between mb-3'>
              <Button className="btn custom-btn p-2 me-2 mb-2" variant="secondary" onClick={showLessMembers}>
                Show Less <FontAwesomeIcon icon={faChevronUp} /> {/* Show "up" icon */}
              </Button>
              <Button className="btn custom-btn p-2 mb-2" variant="secondary" onClick={loadMoreMembers}>
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </Button>
            </div>

          ) : (
            <div className="text-center mb-3">
              <Button className="btn custom-btn p-2" variant="secondary" onClick={loadMoreMembers}>
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </Button>
            </div>
          )}
        </div>
      )}
      {visibleMembers >= teamMembers.length ? (
        <div className='text-center mb-3'>
          <Button className="btn custom-btn p-2" variant="secondary" onClick={showLessMembers}>
            Show Less <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </div>

        ) : (
          <></>
      )}


      {/* Modal */}
      <Modal dialogClassName="custom-modal mt-4 " show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedMember?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='row align-items-center'>
            <div className="col-lg-6 col-md-12 col-12">
              {selectedMember?.image && (
                <img src={selectedMember.image} alt={selectedMember.name} className=" img-fluid mb-3 modal-img" />
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <h3>{selectedMember?.title}</h3>
              <p align='justify'>{selectedMember?.bio}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='custom-btn w-100' variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Team;
