// partners and clients page

// import react
import React, { useState, useEffect } from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/PartnersAndClients.css';
import { Card } from 'react-bootstrap';

//import fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

// import partner-links.json from src
import partnerLinks from '../partners-links.json';


const PartnersAndClients = () => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Fetch the directory listing HTML page
                const res = await fetch('https://core.ps/assets/clients/');
                const text = await res.text();

                // Parse the HTML content to extract image URLs
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');
                const links = doc.querySelectorAll('a[href$=".jpeg"], a[href$=".png"], a[href$=".jpg"], a[href$=".gif"]');


                // Extract and set the image URLs
                const imageUrls = Array.from(links).map((link) => {
                    const encodedHref = link.getAttribute('href');
                    const decodedHref = decodeURI(encodedHref); // Decode to handle special characters
                    return decodedHref;
                });

                // console.log(imageUrls);
                setImages(imageUrls);
            } catch (err) {
                console.error(err);
            }
        };
        fetchImages();
    }, []);

    // Extract names from image names
    const names = images.map((image) => {
        const img = image.split('.')[0];
        // eslint-disable-next-line no-unused-vars
        const [sector, val, logo] = img.split('-')
        const name = val.replace(/_/g, ' ');

        return [sector, name, image];
    });

    // seperate into sectors
    const publicPartners = names.filter((name) => name[0].includes('public'));
    const privatePartners = names.filter((name) => name[0].includes('private'));
    const donorngoPartners = names.filter((name) => name[0].includes('donorngo'));

    console.log(publicPartners);
    console.log(privatePartners);
    console.log(donorngoPartners);

    return (
        <div className="container">
            <header >
                <div className='text-center'>
                    <h1 className='text-uppercase title'>
                        <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                        <span className='px-3'>
                            Partners & Clients
                        </span>
                        <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                    </h1>
                </div>
                <div className='container p-4'>
                    <h5 align='justify'>In the short time Core Associates has been around, our company has nurtured a wide range of local and international relations and business associations.  CORE ASSOCIATES, through its founders and associates, has an extensive network and strong relations with public and private sectors as local actors within the framework of implementing various projects.</h5>
                </div>
            </header>
            <body className='' >
                <h2> Public Sector Partners</h2>
                <h5 className='p-3' align='justify'>Close relations have been established with the many representatives of the public sector involved, including, but not limited to the following:</h5>
                <div className='row'>
                    {publicPartners.map((partner) => (
                        <div className='col-lg-4 col-md-4 col-12 mb-3'>
                            <a href={partnerLinks[partner[2]]} className="text-decoration-none " target='_blank' rel='noreferrer'>
                                <Card className='p-2 partners-card bg-light'>
                                    <div className='bg-white w-100 text-center'>
                                        <Card.Img
                                            variant="top"
                                            style={{ width: '200px', height: '200px', objectFit: 'contain' }}

                                            src={encodeURI(`https://core.ps/assets/clients/${partner[2]}`)}
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='text-center'>The {partner[1]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    ))}
                </div>

                <hr></hr>
                <h2> Private Sector Partners</h2>
                <h5 className='p-3' align='justify'>CORE ASSOCIATES’ involvement with the Palestinian Private sector has been direct in forging working relations with a large number of private sector representatives who have been involved as stakeholders and/or partners within work of CORE ASSOCIATES, including the following:</h5>
                <div className='row'>
                    {privatePartners.map((partner) => (
                        <div className='col-lg-4 col-md-4 col-12 mb-3'>
                            <a href={partnerLinks[partner[2]]} className="text-decoration-none " target='_blank' rel='noreferrer'>
                                <Card className='p-2 partners-card bg-light'>
                                    <div className='bg-white w-100 text-center'>
                                        <Card.Img
                                            variant="top"
                                            style={{ width: '200px', height: '200px', objectFit: 'contain' }}

                                            src={encodeURI(`https://core.ps/assets/clients/${partner[2]}`)}
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='text-center '>The {partner[1]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    ))}
                </div>
                <hr></hr>
                <h2> Donor and NGO Partners</h2>
                <h5 className='p-3' align='justify'>CORE ASSOCIATES has worked closely with international representatives which gives the company access to an extensive network of international actors concerned with trade and economic development in Palestine. These include:</h5>
                <div className='row'>
                    {donorngoPartners.map((partner) => (
                        <div className='col-lg-4 col-md-4 col-12 mb-3'>
                            <a href={partnerLinks[partner[2]]} className="text-decoration-none " target='_blank' rel='noreferrer'>
                                <Card className='p-2 partners-card bg-light'>
                                    <div className='bg-white w-100 text-center'>
                                        <Card.Img
                                            variant="top"
                                            style={{ width: '200px', height: '200px', objectFit: 'contain' }}

                                            src={encodeURI(`https://core.ps/assets/clients/${partner[2]}`)}
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='text-center '>The {partner[1]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    ))}
                </div>
            </body>
        </div>
    );
}

export default PartnersAndClients;
