// services page

// import react
import React from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

// import components
import ServicesDescCard from '../components/ServicesDescCard';

//import fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';



const Services = () => {
    return (
        <div className="container">
            <header >
            <div className='text-center mb-5'>
                <h1 className='text-uppercase title'>
                    <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                    <span className='px-3'>
                        Core Services
                    </span>
                    <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                </h1>
            </div>     
            <div className='row align-items-center mb-5'>
                <div className='col-lg-6 col-md-12 col-12 mb-3'>
                    <img src='https://core.ps/assets/global/coree.png' alt='services background' className='img-fluid' />
                </div>
                <div className='col-lg-6 col-md-12 col-12 mt-2'>
                    <h5 align='justify'>
                    At <strong>CORE ASSOCIATES</strong>, we are committed to driving economic growth and supporting our clients through expertise, collaboration, and a deep understanding of the Palestinian business landscape. Partner with us today and experience the difference firsthand.
                    </h5>
                </div>
            </div>

            <ServicesDescCard />
                
            </header>
        </div>
    );
}

export default Services;