// about overview component

import React from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/AboutOverview.css';

// import components



const AboutOverview = () => {
    return (
        <><div className="row" data-aos="fade-right">
            <div className="row col-sm-12 col-md-12 col-lg-4">
                <div className="col-sm-12 col-md-12 col-lg-5 text-center align-items-center p-2">
                    <img src="https://core.ps/assets/global/who-we-are.png" alt="who we are" className="img-fluid about-img" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-7 text-center align-items-center">
                    <h2 className=''>Who We Are</h2>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 p-3 align-items-center">
                <h6 className='lh-sm' align='justify'>
                    <strong>CORE ASSOCIATES</strong> is a Palestinian consulting firm that provides a wide range of services to the public and private sectors. <strong>CORE ASSOCIATES</strong> was established in October, 2010 as a Limited Liability Private Shareholding Company in accordance with Palestinian Company Registration requirements. Since its inception, the company has signed contracts and executed projects with internationally renowned business associations, institutions, and Financing agencies (USAID, GIZ, European Union, and UN).
                </h6>
            </div>
        </div>
            <div className="row" data-aos="fade-left">
                <div className=" row col-sm-12 col-md-12 col-lg-4">
                    <div className="col-sm-12 col-md-12 col-lg-5 text-center align-items-center p-2">
                        <img src="https://core.ps/assets/global/what-we-do.png" alt="what we do" className="img-fluid about-img" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-7 text-center align-items-center">
                        <h2 className=''>What We Do</h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 p-3 align-items-center">
                    <ul className='list-unstyled '>
                        <li>
                            <h6 className='lh-sm' align='justify'>
                                We provide timely, pragmatic,  and cost-efficient advice and training in the context of economic and trade policy and negotiations, the formulation and implementation of national policy and private sector development. Our clients include governments, international governmental organizations as well as NGOs, trade associations and private businesses.
                            </h6>
                        </li>
                        <li>
                            <h6 className='lh-sm' align='justify'>
                                <strong>CORE ASSOCIATES’ </strong> approach, stemming from its social responsibility, is to keep in mind the importance of building institutions and human resources in all our activities, incorporating cross cutting approaches such as innovation, gender equality, social protection, and empowerment. Our main focus is on the development and enabling of private sector as the main engine for socio-economic growth. Our approach is demand-driven and highly flexible.
                                Throughout the years, <strong>CORE ASSOCIATES</strong> has built a close network of local consultants and experts in a variety of socio-economic sectors, in addition to a solid network of international companies and consultants in our areas of expertise.
                            </h6>
                        </li>
                    </ul>
                </div>
            </div></>
    );
}

export default AboutOverview;