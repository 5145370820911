// website footer component

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className="">
            <footer className="text-center text-lg-start ">
                <section className="d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    {/* Left */}
                    <div style={ window.innerWidth <= 768 ? {fontSize:'14px'} : {}} className='me-3'>
                        <span> Connect with us on social networks:</span>
                    </div>
                    {/* Right */}
                    <div>
                        <div className="row align-items-center">
                            <a href="mailto:info@core.ps" className=" col-lg-4 col-md-4 col-4">
                                <FontAwesomeIcon icon={faEnvelope} size={ window.innerWidth <= 768 ? '' : '2x'} />
                            </a>
                            <a href="https://www.facebook.com/Coreconsult/" className="col-lg-4 col-md-4 col-4">
                                <FontAwesomeIcon icon={faFacebook} size={ window.innerWidth <= 768 ? '' : '2x'} />
                            </a>
                            <a href="https://www.linkedin.com/company/core-associates/" className="col-lg-4 col-md-4 col-4">
                                <FontAwesomeIcon icon={faLinkedinIn} size={ window.innerWidth <= 768 ? '' : '2x'} />
                            </a>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-6 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Core Associates</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '6rem', backgroundColor: '#7c4dff', height: '2px' }} />
                                <img src="https://core.ps/assets/global/core-logo.png" alt="Core Associates Logo" className="img-fluid p-3" />
                            </div>


                            <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Contact Us</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '6rem', backgroundColor: '#7c4dff', height: '2px' }} />
                                <p>
                                    <FontAwesomeIcon icon={faEnvelope} color='grey' />
                                    <a className="ms-3 custom-link" href="mailto:info@core.ps">info@core.ps</a>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faPhone} color='grey' />
                                    <a className="ms-3 custom-link" href="tel:+970-2-2412102">+970-2-2412102</a>
                                </p>
                                <a href="/contact" type="button" className=" p-2 btn btn-primary custom-btn col-lg-6 col-md-12 col-12 justify-content-end rounded ">
                                    Work With Us
                                </a>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    © 2023 Copyright:
                    <a className="ms-3 text-dark" href="https://core.ps/">Core Associates</a>
                </div>
            </footer>
        </div>
    );
};

export default Footer;