// services card component
import React from 'react';
import { Parallax, Background } from 'react-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/ServicesCard.css';


const ServicesCard = () => {
    return (
        <Parallax
            bgImage="https://core.ps/assets/global/services-card-bg-02.png"
            strength={200} // Adjust the strength as needed for the parallax effect
            className='rounded'
        >
            <Background className="custom-card-bg"></Background>
                <div className=" custom-card card-body p-5">
                    <h3 className="card-title">Our Main Services</h3>
                    <ul className="list-unstyled">
                        <li>Business Development Services</li>
                        <li>WTO Accessions and Trade Negotiations</li>
                        <li>Studies and Opinions</li>
                        <li>Trainings, Workshops, Stakeholder Consultations</li>
                        <li>Trade Policy Formulation and Implementation</li>
                        <li>Digital Development</li>
                        <li>Monitoring, Evaluation, Accountability & Learning</li>
                    </ul>
                    <div className="row custom-row mt-4">
                        <div className="">
                            <a href="/services" className=" w-100 btn btn-primary custom-btn ">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
        </Parallax>
    );
};


export default ServicesCard;


