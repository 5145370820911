import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';

// Import CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/ServicesDescCard.css';

const ServicesDescCard = () => {
  // Sample service data
  const services = [
    {
      name: 'Business Development',
      image: 'https://core.ps/assets/global/services/business-development-services.png',
      description: 'Our comprehensive due diligence services equip Palestinian companies with the tools they need to enhance their competitive advantage and expand their market share. We conduct in-depth business analysis, helping companies gain a deeper understanding of their target market and overall business environment. With this knowledge, our clients can make informed decisions regarding production, marketing, finance, and market entry. Additionally, we assist companies in preparing business plans for future operations, introducing new products, and identifying the most suitable financial resources, including loans, joint ventures, or grants.',
    },
    {
      name: 'WTO Accessions and Trade Negotiations',
      image: 'https://core.ps/assets/global/services/trade-negotiations.jpeg',
      description: 'CORE ASSOCIATES provides invaluable support to stakeholders involved in multilateral, regional, and bilateral negotiations. We work closely with the Palestinian government and private sector, aiding them in bilateral trade negotiations with the EC (Euro-Med) and beyond. Our expert team assists in preparing strategic positions for trade talks and effectively communicating these positions to policymakers and negotiators.',
    },
    {
      name: 'Trade Policy Formulation and Implementation of Measures',
      image: 'https://core.ps/assets/global/services/trade-policy-formulation-implementation.png',
      description: 'We offer expert advice, consultations, and drafting support in trade regulation, liberalization of trade in services, and trade facilitation. Our team helps clients align their trade policies with international standards, formulate effective policies, and implement measures that enhance the trade enabling environment. We also assist in structuring public-private dialogues and establishing frameworks for private sector input into policy formulation.',
    },
    {
        name: 'Studies and Opinions',
        image: 'https://core.ps/assets/global/services/studies-opinions.jpeg',
        description: 'CORE ASSOCIATES conducts comprehensive studies and provides expert opinions on trade, economic growth, and private sector development-related issues. Our research covers topics such as economic growth requirements, market entry strategies, trade facilitation, and sector-specific assessments. We also offer guidance on establishing effective partnerships between the public, private, and academic sectors and provide advice on internal reforms to improve the investment climate.'
    },
    {
        name: 'Trainings, Workshops, Stakeholder Consultations',
        image: 'https://core.ps/assets/global/services/training-workshops-and-consultation.webp',
        description: 'We deliver tailored training programs, workshops, and stakeholder consultations designed to meet the specific needs of our clients. Our offerings range from introductory sessions to highly specialized events, including workshops spanning from single-day sessions to intensive three-month courses. Some of the areas we cover include WTO awareness workshops, trade negotiation and WTO accession training for government officials and stakeholders, trade facilitation and customs reform training, and consultative workshops on trade policy, trade negotiations, and economic policy involving both public and private sectors.'
    },
    {
        name: 'Digital Development',
        image: 'https://core.ps/assets/global/services/digital-development.png',
        description: 'At CORE ASSOCIATES, we guide and assist in developing programming pertaining to digital development and work with our clients to reaching goals furthering digitally enabled economic growth in Palestine and other countries. Our services vary from creating technological educational programs to foster awareness and skills within the community, to providing studies and analyses of the state of the digital environment, as well as communicating the work and intent of our clients to other public and private sector stakeholders.'
    },
    {
        name: 'Monitoring, Evaluation, Accountability & Learning (MEAL)',
        image: 'https://core.ps/assets/global/services/meal.jpeg',
        description: 'At CORE ASSOCIATES, we specialize in providing comprehensive MEAL (Monitoring, Evaluation, Accountability, and Learning) services that empower organizations to achieve greater impact and sustainability. Our dedicated team of experts is committed to guiding you through every step of the project cycle. From meticulous monitoring and data collection to insightful evaluation and analysis, we ensure that your initiatives are on track and delivering the desired results. Our accountability mechanisms are designed to promote transparency and engage stakeholders effectively. Moreover, our emphasis on continuous learning ensures that your organization adapts and improves based on real-time insights.'
    },
    

    // Add more services here
  ];

  // State to manage modal visibility and selected service
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Function to show the modal with service details
  const showServiceModal = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  return (
    <div className="container">
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-6 mb-4 h-100" data-aos="fade-left" data-aos-delay="300">
            <Card
              className="service-card bg-light border-0"
              onClick={() => showServiceModal(service)}
              style={{ cursor: 'pointer', height: '400px' }}
            >
              {service.image && (
                <Card.Img
                  variant="top"
                  src={service.image}
                  alt={service.name}
                  style={{alignContent: 'center', height: '240px' }}
                />
              )}
              <Card.Body className="text-center d-flex flex-column">
                <Card.Title>{service.name}</Card.Title>
                <Card.Text className="click-prompt text-center" >Click for more details</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal dialogClassName="custom-modal mt-4 " show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedService?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              {selectedService?.image && (
                <img
                  src={selectedService.image}
                  alt={selectedService.name}
                  className="img-fluid mb-3 modal-img"
                />
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <h3>Service Details</h3>
              <p>{selectedService?.description}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="custom-btn w-100" variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServicesDescCard;
