// not found page

// import react
import React from 'react';



const NotFound = () => {
    return (
        <div className="container">
            <header >
                <h1>404 Page Not Found</h1>
            </header>
        </div>
    );
}

export default NotFound;