// contact us page

// import react
import React from 'react';

// import components
import GoogleMapComponent from '../components/GoogleMap';
import ContactForm from '../components/ContactForm';

//import fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';



const Contact = () => {
    return (
        <div className="container">
            <header >
                <div className='text-center'>
                    <h1 className='text-uppercase title'>
                        <FontAwesomeIcon icon={faCaretRight} style={{color: "#98cb4f",}} />
                        <span className='px-3'>
                            Contact Us
                        </span>
                        <FontAwesomeIcon icon={faCaretLeft} style={{color: "#98cb4f",}} />
                    </h1>
                </div>
                <br />
            </header>
            <body className='' >
                <GoogleMapComponent />
                <br />
                <br />
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 p-3 align-items-center">
                        <h2>Send us a message!</h2>
                        <br />
                        <h4>Do not hesitate to contact us directly. 
                            Fill out the form below and we will get back to you as soon as possible.</h4>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <ContactForm />
                    </div>
                </div>
            </body>
        </div>
    );
}

export default Contact;