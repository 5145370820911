import React from 'react';
import { Parallax } from 'react-parallax'; // Import the Parallax component
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/AboutCard.css';

const AboutCard = () => {
    return (
        <Parallax strength={200} bgImage="https://core.ps/assets/global/contact-us-background.jpeg" bgClassName='custom-img'>
            <div className='p-5 m-5' style={{height: '400px'}}>
                <h2 className='mb-2 text-white'>About Us</h2>
                <h3 className='fw-light text-white'>Learn more about us:</h3>
                <h3 className='fw-light text-white'>Who we are, what we do, and why we do it.</h3>
            </div>
        </Parallax>
    );
}

export default AboutCard;
