import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';



function ProjectList({ projects }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setShowModal(false);
  };

  return (
    <div className="project-list row mt-3 p-3">
      {projects.map((project, index) => (
        <div key={index} className=" col-lg-6 col-md-6 col-12 mb-3 p-2 h-100" >
          <Card className="project-card bg-light border-0 rounded-0" style={{ cursor: 'pointer' }} onClick={() => openModal(project)}>
            <Card.Body className='row align-items-center'>
              <div className='col-lg-6 col-md-12 col-12 '>
                <Card.Title className=' proj-card-title'>{project['Name of project']}</Card.Title>
                <Card.Text className='proj-card-text' >{project['Period of activity']}</Card.Text>
              </div>
              <div className='col-lg-6 col-md-12 col-12'>
                <Card.Text><strong>Client:</strong> {project['Client']}</Card.Text>
                <Card.Text><strong>Area of Focus:</strong> {project['Area of Focus']}</Card.Text>
                <Card.Text><strong>Status:</strong> {project['status'].toLowerCase()}</Card.Text>
                <Card.Text className="click-btn"  >View Details</Card.Text>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}

      {selectedProject && (
      <Modal dialogClassName="custom-modal mt-4" show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
            <Modal.Title className='proj-card-title'>
              {selectedProject['Name of project']} - <span className='bg-light proj-card-text'> {selectedProject['Period of activity']}</span>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4 col-12">
                <p className=''><strong>Client:</strong> {selectedProject['Client']}</p>
               
                <p><strong>Area of Focus:</strong> {selectedProject['Area of Focus']}</p>
                <p><strong>Status:</strong> {selectedProject['status'].toLowerCase()}</p>
              </div>
              <div className="col-lg-8 col-12">
                <h4>Types of Activities Undertaken</h4>
                <p align='justify'> {selectedProject['Types of activities undertaken']}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default ProjectList;
