/* eslint-disable react-hooks/exhaustive-deps */
// projects page


// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/Projects.css';

// import components
import Tabs from '../components/Tabs';
import Filters from '../components/Filters';
import ProjectList from '../components/ProjectList';

// import fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

import React, { useState, useEffect } from 'react';
import projectData from '../projects.json';


function Projects() {
    const [projects, setProjects] = useState([]);
    const [activeTab, setActiveTab] = useState('All');
    const [areaOfFocusFilter, setAreaOfFocusFilter] = useState('');
    const [timeRangeFilterType, setTimeRangeFilterType] = useState('');
    const [specificYear, setSpecificYear] = useState('');
    const [yearRange, setYearRange] = useState([2010, 2026]);
    const [availableYears, setAvailableYears] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showTabs, setShowTabs] = useState(false);

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    useEffect(() => {
        setProjects(projectData);

        const availableYears = [];
        for (let i = 2010; i <= 2026; i++) {
            availableYears.push(i);
        }
        setAvailableYears(availableYears);
    }, []);

    const filterProjects = () => {
        let filteredProjects = projectData;

        if (activeTab === 'Complete') {
            filteredProjects = filteredProjects.filter((project) => {
                return project['status'].toLowerCase() === 'complete';
            });
        } else if (activeTab === 'Ongoing') {
            filteredProjects = filteredProjects.filter((project) => {
                return project['status'].toLowerCase() === 'ongoing';
            });
        }

        if (areaOfFocusFilter) {
            filteredProjects = filteredProjects.filter((project) => {
                return project['Area of Focus'].toLowerCase() === areaOfFocusFilter;
            });
        }

        if (timeRangeFilterType === 'specificYear' && specificYear) {
            filteredProjects = filteredProjects.filter((project) => {
                const projectYear = parseInt(project['Period of activity'].split('-')[0]);
                return projectYear === parseInt(specificYear);
            });
        } else if (timeRangeFilterType === 'yearRange') {
            filteredProjects = filteredProjects.filter((project) => {
                const projectYear = parseInt(project['Period of activity'].split('-')[0]);
                return projectYear >= yearRange[0] && projectYear <= yearRange[1];
            });
            
        }

        setProjects(filteredProjects);
    };

    useEffect(() => {
        filterProjects();
    }, [activeTab, areaOfFocusFilter, timeRangeFilterType, specificYear, yearRange]);


    return (
        <div className='p-3'>
            <div className='text-center mb-5'>
                <h1 className='text-uppercase title'>
                    <FontAwesomeIcon icon={faCaretRight} style={{ color: "#98cb4f", }} />
                    <span className='px-3'>
                        Core Projects
                    </span>
                    <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#98cb4f", }} />
                </h1>
            </div>
            <div className='row mb-2'>
                <div className='col-lg-6 col-md-6 col-12 mb-2'>
                    <Tabs 
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        showTabs={showTabs}
                        setShowTabs={setShowTabs}
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-12 text-end'>
                    <Filters
                        areaOfFocusFilter={areaOfFocusFilter}
                        setAreaOfFocusFilter={setAreaOfFocusFilter}
                        timeRangeFilterType={timeRangeFilterType}
                        setTimeRangeFilterType={setTimeRangeFilterType}
                        specificYear={specificYear}
                        setSpecificYear={setSpecificYear}
                        yearRange={yearRange}
                        setYearRange={setYearRange}
                        availableYears={availableYears}
                        showFilters={showFilters}
                        toggleFilters={toggleFilters}
                    />
                </div>
            </div>
            <ProjectList projects={projects} />
        </div>
    );
}

export default Projects;


