import React from 'react';
import { Collapse } from 'react-bootstrap';

function Filters({
  areaOfFocusFilter,
  setAreaOfFocusFilter,
  timeRangeFilterType,
  setTimeRangeFilterType,
  specificYear,
  setSpecificYear,
  yearRange,
  setYearRange,
  showFilters, // Add showFilters prop
  toggleFilters, // Add toggleFilters prop
}) {
  const handleAreaOfFocusChange = (e) => {
    setAreaOfFocusFilter(e.target.value);
  };

  const handleTimeRangeTypeChange = (e) => {
    setTimeRangeFilterType(e.target.value);
  };

  return (
    <div className="filters text-end">
        <button
          type="button"
          className="btn custom-btn mb-1"
          onClick={toggleFilters} // Use toggleFilters to toggle visibility
          aria-expanded={showFilters}
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>

        <Collapse in={showFilters}>
          <div>
            <button className='btn fw-light mb-1' onClick={() => { setAreaOfFocusFilter(''); setTimeRangeFilterType(''); setSpecificYear(''); setYearRange([2010, 2026]); }}>
              Clear Selection
            </button>
            <select
              className="form-select p-3 mb-2"
              value={areaOfFocusFilter}
              onChange={handleAreaOfFocusChange}
            >
              <option value=''>Filter Based on Area of Focus</option>
              <option value="trade & economy">I. Trade & Economy</option>
              <option value="policy & planning">II. Policy & Planning</option>
              <option value="agriculture, food security, environment">III. Agriculture, food security, environment</option>
              <option value="youth socio economic empowerment, innovation">IV. Youth socio economic empowerment, Innovation</option>
              <option value="social development and protection">V. Social Development and Protection</option>
            </select>
            <select
              id="timeRangeFilterType"
              className="form-select p-3 mb-2"
              value={timeRangeFilterType}
              onChange={handleTimeRangeTypeChange}
            >
              <option value=''>Filter Based on Period of activity</option>
              <option value="specificYear">Specific Year</option>
              <option value="yearRange">Year Range</option>
            </select>
            {timeRangeFilterType === 'specificYear' ? (
              <input
                type="text"
                id="specificYear"
                placeholder="Enter a year"
                value={specificYear}
                onChange={(e) => setSpecificYear(e.target.value)}
              />
            ) : timeRangeFilterType === 'yearRange' ? (
              <div className='search row'>
                <div className=' col-lg-4 col-md-12 col-12'>
                  <span>Year Range: {yearRange[0]} - {yearRange[1]}</span>
                </div>
                <div className=' col-lg-4 col-md-6 col-12'>
                  <input
                    type="range"
                    id="yearRange"
                    min="2010"
                    max="2026"
                    step="1"
                    value={yearRange[0]}
                    onChange={(e) => setYearRange([parseInt(e.target.value), yearRange[1]])}
                  />
                </div>
                <div className=' col-lg-4 col-md-6 col-12'>                  
                  <input
                    type="range"
                    id="yearRange"
                    min="2010"
                    max="2026"
                    step="1"
                    value={yearRange[1]}
                    onChange={(e) => setYearRange([yearRange[0], parseInt(e.target.value)])}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Collapse>
    </div>
  );
}

export default Filters;
